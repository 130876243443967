<template>
    <div class="container">
        <div class="container-body">
            <div :class="ClientWidth_number > 850 ?'body-box' : 'body-box-phone'">
                <el-row>
                    <el-col :span="6">
                        <div class="grid-content bg-purple title" style="height: 60px; width: 208px;font-weight:900;">
                            <h3>网络威胁管控平台</h3>
                            <div class="body-box2" v-if="ClientWidth_number < 500">
                                <el-button
                                        @click="jump()"
                                        class="pnone-button"
                                        type="primary">
                                    试用申请
                                </el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>

                <el-row v-if="ClientWidth_number > 850 ">
                    <el-col :span="24">
                        <div class="grid-content bg-purple content"
                             style="margin-bottom:2vw;;width:50vw;margin:0 auto;">
                            <img alt="" src="../../assets/twoImg.png" style="width:100%;height:100%;">
                        </div>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            <div>
                                面对高度自动化、隐蔽化的网络攻击，传统人工鉴别的方式难以应对海量的攻击告警，攻击处置周期长，防守方面临的挑战日益严峻。
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            赛尔安全作为国家网防第一梯队立足于构建实战化、体系化、常态化防御能力，与国内顶级科研机构合作，在业内率先推出网络威胁管控系统——AD
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            AD系统实时采集态势感知、网络流量等多源威胁信息，融合AD云中心外部威胁情报，通过大数据分析，精准锁定攻击行为，基于人工智能技术定制最佳阻断路径，调度防火墙、交换机、IPS、WAF、服务器等多种设备即时阻断威胁。
                        </div>
                    </el-col>
                    <el-col :offset="3" :span="24" v-if="ClientWidth_number > 850 ">
                        <div class="grid-content bg-purple content">
                            <img alt="" src="../../assets/111.png" style="width:75%;margin:0 auto;;">
                        </div>
                    </el-col>
                    <el-col :span="24" v-else>
                        <div class="grid-content bg-purple content">
                            <img alt="" src="../../assets/111.png" style="width:100%;margin-top:5px;">
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <div class="grid-content bg-purple title" style="height: 25px; width: 120px">
                            <h3>平台功能</h3>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                </el-row>
                <el-row>
                    <el-col :span="ClientWidth_number > 850 ? 12 : 24">
                        <div class="grid-content bg-purple content">
                            <div class="content-box" style="">
                                <div style="width: 35%; height: 60%">
                                    <img alt="" src="../../assets/two1.png"/>
                                </div>
                                <div style="width: 90%; ">
                                    <div class="subtitle">
                                        多源威胁情报数据整合
                                    </div>
                                    <div style="width: 100%; height: 70%; pdding: 10px">
                                        适配主流的网络安全监测产品、态势感知产品、流量分析产品等，实现多种来源的威胁情报的自动整合。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="ClientWidth_number > 850 ? 12 : 24">
                        <div class="grid-content bg-purple content">
                            <div class="content-box" style="">
                                <div style="width: 35%; height: 60%">
                                    <img alt="" src="../../assets/two2.png"/>
                                </div>
                                <div style="width: 90%; height: 90%">
                                    <div class="subtitle">
                                        精准锁定威胁
                                    </div>
                                    <div style="width: 100%; height: 70%; pdding: 10px">
                                        通过对威胁情报数据进行机器学习与建模分析，形成精确的实时安全情报信息，准确定位威胁源。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="ClientWidth_number > 850 ? 12 : 24">
                        <div class="grid-content bg-purple content">
                            <div class="content-box" style="">
                                <div style="width: 35%; height: 60%">
                                    <img alt="" src="../../assets/two3.png"/>
                                </div>
                                <div style="width: 90%; ">
                                    <div class="subtitle">
                                        智能定制路径实时阻断
                                    </div>
                                    <div style="width: 100%; height: 70%; pdding: 10px">
                                        基于人工智能技术，根据威胁源、发生的时间、危险程度、受攻击目标的安全权重等要素定义阻断规则，定制最佳阻断路径实时自动阻断，不影响用户正常的业务应用。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="ClientWidth_number > 850 ? 12 : 24">
                        <div class="grid-content bg-purple content">
                            <div class="content-box" style="">
                                <div style="width: 35%; height: 60%">
                                    <img alt="" src="../../assets/two4.png"/>
                                </div>
                                <div style="width: 90%; ">
                                    <div class="subtitle">
                                        宽严相济的策略
                                    </div>
                                    <div style="width: 100%; height: 70%; pdding: 10px">
                                        基于威胁变化情况，智能调整自动阻断策略，常态化、重保严防和攻防演习场景应用不同安全策略可智能切换，更灵活，更易用。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="ClientWidth_number > 850 ? 12 : 24">
                        <div class="grid-content bg-purple content">
                            <div class="content-box" style="">
                                <div style="width: 35%; height: 60%">
                                    <img alt="" src="../../assets/two5.png"/>
                                </div>
                                <div style="width: 90%; ">
                                    <div class="subtitle">
                                        一键断网
                                    </div>
                                    <div style="width: 100%; height: 70%; pdding: 10px">
                                        按时间段等条件对重点防护目标制定阻断策略，并即时执行。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="ClientWidth_number > 850 ? 12 : 24">
                        <div class="grid-content bg-purple content">
                            <div class="content-box" style="">
                                <div style="width: 35%; height: 60%">
                                    <img alt="" src="../../assets/two6.png"/>
                                </div>
                                <div style="width: 90%; ">
                                    <div class="subtitle">
                                        情报接收与分享
                                    </div>
                                    <div style="width: 100%; height: 70%; pdding: 10px;">
                                        权威情报源，结合国家权威机构威胁信息源与流量分析，精准提供威胁IP信息。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                </el-row>
            </div>

            <div class="body-box2" v-if="ClientWidth_number > 850">
                <el-button @click="jump()" class="pc-button" size="medium" type="primary">试用申请</el-button>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                xxx: "\u3000\u3000",
                ClientWidth_number: document.documentElement.clientWidth,
                ruleForm: {
                    name: "",
                    contacts: "",
                    phone: "",
                    mome: "",
                },
            };
        },
        created() {
            this.getClientWidth();
        },
        methods: {
            jump() {
                this.$nextTick(() => {
                    window.location.href = 'https://ad.cersnet.com/#/testApply';
                })
            },
            getClientWidth() {
                window.onresize = () => {
                    this.ClientWidth_number = document.documentElement.clientWidth;
                };
            },
        },
    };
</script>

<style lang='less' scoped>
    @baseWidth: 1980;
    .pxfix(@w) {
        width: (@w / @baseWidth) * 100vw;
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .content-box {
        min-height: 110px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .container-body {
        .pxfix(1980);
        min-height: 700px;
        margin: 0 auto;
        background: #eff3f8;
        // margin-top: 20px;
        display: flex;
        flex: 1;

        .title {
            font-size: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-weight: normal;
            font-family: "microsoft yahei";
            color: #555;
        }

        .subtitle {
            width: 97%;
            height: 30%;
            line-height: 200%;
            font-weight: 900;
        }

        .content {
            margin: 0 1rem;
            color: #555;
            font-size: 0.8rem;
            line-height: 25px;
            font: 'microsoft yahei';
        }

        .body-box {
            .pxfix(1040);
            margin: 1.5rem auto;
            //  flex:1;
            padding: 0 3vw;
            background: #fff;
        }

        .body-box-phone {
            background: #fff;
            margin: 1.5rem auto;
        }

        .body-box:hover {
            box-shadow: 0 0 10px #888888;
        }
    }

    .footer {
        line-height: 50px;
        background: #2f3034;
        width: 100%;
        font-size: 12px;
        color: #fff;
        text-align: center;
    }
</style>
<style>
    .body-box2 > .pc-button {
        font-size: 20px;
        width: 150px !important;
        height: 60px !important;
        position: fixed;
        top: 150px;
        right: 5vw;;
    }

    .body-box2 > .pnone-button {
        /* font-size: 20px; */
        /* width: 150px !important;
        height: 60px !important; */
        position: fixed;
        z-index: 99;
        font-weight: 600;
        top: 100px;
        right: 0;
    }
</style>
